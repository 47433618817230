<template>
	<div class="my-5">
		<slot></slot>
	</div>
</template>


<script>
    export default {
        props:{
            
        },
        name: 'Change',
        computed: {
        },
        components:{
        }
    }
</script>